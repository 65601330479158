.header {
    height: 70vh;
    border: 1px solid gray;
    border-radius: 0px 0px 20px 20px;
    background-repeat: no-repeat;
    background-position: center; 
    align-items: center;
    background-size: cover; 
    margin:auto;
    margin-left:5%;
    margin-right:5%;
}

#logoBlablafoot {

  align-items: center;
  
}

#bloc_filtre {

  height: 60vh;
  border: 2px solid red;
}


@media (max-width: 767px) {

    .header {
      background-image: url(../assets/app/header_mobile.png);
    }

    #logoBlablafoot {

      margin-top: auto;
    
    }

     

}

@media (min-width: 768px) and (max-width: 1024px) {
   
    .header {
      background-image: url(../assets/app/header_mobile.png);
    }

    #logoBlablafoot {

      margin-left: 100px;
     
    }

    #bouton_news {

      border-radius: 2px;  
     
    }

    #bouton_replay {

      border-radius: 2px;
      
    
    }

    #bouton_live {

      border-radius: 2px;
      
    
    }

}
  
@media (min-width: 1025px) {
  
    .header {
  
      background-image: url(../assets/app/header-desktop.png);    
  
    }

    #logoBlablafoot {

      margin-left: 100px; 
      
    }

    #bouton_news {

      border-radius: 5px;  
      
    }

    #bouton_replay {

      border-radius: 5px;
         
    }

    #bouton_live {

      border-radius: 5px;
         
    }

}