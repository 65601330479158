.App {
  text-align: center;
}

#cardNews {

  background-color: rgb(43, 163, 97);
  border-radius: 15px 15px 0px 0px;
}

#card_contenu {
  width: 90%;
  margin: auto;
}

#card_img {
  background-size: cover;
  width: 100%;
  margin: auto;
}

#bloc_like {
  width: 90%;
  margin: auto;
}

#card_hatg {
  border-radius: 0px 0px 15px 15px;
}

#bloc_content{

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 20px;     /* fallback */
   max-height: 75px;      /* fallback */
   -webkit-line-clamp: 3; /* number of lines to show */
   -webkit-box-orient: vertical;
}

#modal_Share{

 /* background-color: rgba(233, 224, 224, 0.123); */
/* background: rgb(0, 0, 0); */
  background: rgba(184, 240, 247, 0.075);

}
